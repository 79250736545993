/*
 * @Author: luoxia
 * @Date: 2021-03-08 14:10:09
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2022-11-21 09:48:13
 */
export const Offline_Payment = 'Offline_Payment'; // 线下支付
export const Platform_Payment = 'Platform_Payment'; // 第三方平台支付
export const Bank_Payment = 'Bank_Payment'; // 银行卡支付

export const Offline_Payment_Remittance = 'Offline_Payment_Remittance'; // 线下汇款

export const Platform_Payment_WeiXin = 'Platform_Payment_WeiXin';
export const Platform_Payment_ALIPAY = ' Platform_Payment_ALIPAY';

export const WEIXIN_MAX_PAY_AMT = 3000; // 微信支付的最大金额

export const PRODUCT_TYPE = {
  V2APaaS: 11,
  ESS: 11,
  ECS: 12,
  OCR: 13,
  SUPLINK: 14,
  APC: 15,
  APaaS: 17,
  X_ETL: 18,
  RESOURCES_BUNDLE: 10000, // 资源包 前端自己定义后端无对应值 资源包不是一个产品
};

export const NEW_BUY = 1;
export const ANOTHER_ORDER = 2;
export const RENEW = 3;

export const COUPON_STATUS_UNUSED = '1';
export const COUPON_STATUS_USED = '3';
export const COUPON_STATUS_INVALID = '4';

export const couponsSuitProdTableColumns = [
  {
    title: '产品名称',
    dataIndex: 'productName',
  },
  {
    title: '云服务名称',
    dataIndex: 'serviceName',
  },
  {
    title: '服务类型',
    dataIndex: 'productClassifyName',
  },
];

export const AUTH_STATUS_MAP = {
  AUTHENTICATE_NOT: 0,
  AUTHENTICATING: 1,
  AUTHENTICATED: 2,
  AUTHENTICATE_FAILED: 3,
};

export const AUTH_TYPE_MAP = {
  USER_REAL_NAME: 1, //  个人客户
  CORP_REAL_NAME: 2, //  企业客户
  USER_DEVELOPER: 3, //  个人开发者
  CORP_DEVELOPER: 4, //  企业开发者
  CORP_PARTNER: 5, //  合作伙伴
  CORP_PARTNER_AGENT: 6, // 代理商
  CORP_PARTNER_INTE: 7, // 集成商
  CORP_PARTNER_ISV: 8, // 服务商
};
