/*
 * @Author: luoxia
 * @Date: 2021-03-08 14:05:11
 * @LastEditors: luoxia
 * @LastEditTime: 2022-08-09 16:21:34
 */
import React from 'react';
import { Tabs } from 'antd';

import Button from '../Button';

import styles from './index.module.less';

const { TabPane } = Tabs;

class InstanceType extends React.Component {
  item = (proItem, classItem, goPurchase, hiddenBuyBtn) => {
    const state = { classValue: classItem.value, productId: proItem.id };
    return (
      <div key={proItem.id} className={styles.item}>
        <img className={styles.img} src={proItem.thumbnail} alt="产品" />
        <div className={styles.title}>{proItem.productName}</div>
        <p className={styles.detail}>{proItem.description}</p>
        {!hiddenBuyBtn && (
          <Button
            type="primary"
            styles={{
              color: '#525252',
              fontSize: '12px',
              height: '46px',
              background: '#fff',
              position: 'absolute',
              bottom: '0px',
              left: '0px',
              marginLeft: '20px',
            }}
            radius="big"
            width="136px"
            size="middle"
            onClick={(e) => {
              goPurchase(e, state);
            }}
          >
            立即购买
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      ESSProList = [],
      goPurchase,
      title,
      style,
      hiddenBuyBtn,
    } = this.props;
    if (!ESSProList.length) return null;
    return (
      <div style={style} className={styles.instance}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <Tabs defaultActiveKey={`0`}>
            {ESSProList.map((classItem, index) => (
              <TabPane tab={classItem.displayText} key={`${index}`}>
                <div className={styles.itemWrap}>
                  {classItem.products.map((proItem) =>
                    this.item(proItem, classItem, goPurchase, hiddenBuyBtn)
                  )}
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default InstanceType;
